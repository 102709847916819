#overlay,
#overlay-mobile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  z-index: 9990;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

#overlay.visible,
#overlay-mobile.visible {
  opacity: 1;
  pointer-events: auto;
}

.interface {
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-family: 'Futura PT', system-ui, -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 1.2rem;
  height: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;

  transition: background 0.3s ease, color 0.3s ease;
}

.interface.light {
  background: rgba(255, 255, 255, 0.5);
  color: #000;
}

#overlay-mobile.visible .interface {
  align-items: center;
}

/**
* Button
*/

.interface button,
.interface .mock-btn {
  position: relative;
  background: transparent;
  font-family: 'Futura PT', system-ui, -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
    sans-serif;
  color: inherit;
  font-size: 1.5rem;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  text-align: center;
  text-decoration: none;
  border: none;
  padding: 0.5rem;
  opacity: 0.5;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.interface button.active,
.interface .mock-btn.active {
  opacity: 1;
}

.interface button::after,
.interface .mock-btn::after {
  content: '';
  background: #fff;
  width: 30%;
  height: 0.01rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: width 0.3s ease;
}

.interface.light button::after,
.interface.light .mock-btn::after {
  background: #000;
}

.interface button:hover,
.interface .mock-btn:hover {
  opacity: 1;
}

.interface button:hover::after,
.interface button.active::after,
.interface .mock-btn:hover::after,
.interface .mock-btn.active::after {
  width: 80%;
}

/**
* Content
*/

.interface .wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, auto);
  justify-items: center;
  align-items: center;
  height: 100%;
}

.interface__content {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

/**
* Theme
*/

.interface__instructions {
  text-transform: uppercase;
  display: grid;
  justify-items: space-between;
  align-items: center;
  grid-template-columns: auto;
  gap: 2rem;
  row-gap: 1rem;
}

.interface__instructions-mobile {
  text-align: center;
  text-transform: uppercase;
  grid-row: span 2;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  padding: 1rem;
}

.interface__keys {
  font-weight: 300;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.interface__keys.hide {
  opacity: 0;
}

.interface__keys .key {
  background: rgba(255, 255, 255, 0.1);
  letter-spacing: 0.1rem;
  text-align: center;
  display: flex;
  padding: 0.5rem;
  border-radius: 0.5rem;
  transition: background 0.3s ease;
}

.interface__keys .key:hover {
  background: rgba(255, 255, 255, 0.2);
}

.interface__instructions .separator {
  font-weight: 100;
  font-size: 1.5rem;
  opacity: 0.5;
  text-align: center;
}

.interface__credits {
  font-size: 1rem;
  font-weight: 300;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.interface__credits .headphones {
  font-size: 1rem;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  opacity: 0.7;
}

.interface__credits a {
  color: inherit;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
}

.interface__credits a:hover {
  text-decoration: underline;
}

.move-down {
  margin-top: 1rem;
}

@media (min-width: 768px) {
  .interface button,
  .interface .mock-btn {
    font-size: 2rem;
  }

  .interface__instructions {
    grid-template-columns: auto 1fr;
    row-gap: 2rem;
  }
}

/**
* Hints
*/
.interface__hints {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  font-family: 'Futura PT', system-ui, -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
    sans-serif;
  color: #fff;
  font-size: 1.3rem;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem 1rem;

  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.interface__hints.visible {
  opacity: 0.7;
}

.sphere__html {
  pointer-events: none;
}

.additional__menu {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  font-family: 'Futura PT', system-ui, -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
    sans-serif;
  color: #fff;
  font-weight: 300;
  text-align: center;
  display: flex;
  flex-direction: column;
  z-index: 99999;
}

.additional__menu .header {
  font-size: 2rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  padding: 1rem;
}

.additional__menu .hint {
  font-size: 1.3rem;
  opacity: 0.7;
  padding: 1rem;
}
